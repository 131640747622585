// Sizing video boxes for index videos and featured

.video-box {
    // Calc perfect width for desktop main videos
    --_preferred-w: 76vw;
    --_max-h: 70vh; // 74vh if scale down before active
    --_max-w: min(120rem, 76vw); // 80vh if scale down before active

    // calc width for reels
    --_reel-w: min(16vw, 24rem);
    --_reel-offset-top: 0px; // offset main and reel videos if there are any reels
    --_reel-offset-right: 0px; // offset main and reel videos if there are any reels

    &.is-portrait {
        // Redefine settings for portrait videos
        --_max-h: 84vh;
        --_max-w: 40rem;
    }

    &.has-reels {
        // --_max-w: min(100rem, 70vw);
        --_max-h: 64vh;
        --_reel-offset-top: min(8vh, 8rem);
        --_reel-offset-right: calc(var(--_reel-w) / 2);
    }

    @include respond-to(lg) {
        grid-column: 1 / span 1;
        grid-row: 1 / span 1;
        place-self: center center;
        display: grid;
    }
}

.video-box__player {
    @include respond-to(lg) {
        width: min(var(--_preferred-w), var(--_max-h) / var(--aspect-ratio, 0.5));
        max-width: var(--_max-w);
        margin-top: var(--_reel-offset-top);
        margin-right: var(--_reel-offset-right);
        grid-column: 1 / span 1;
        grid-row: 1 / span 1;
        place-self: center center;
    }
}
