/*------------------------------------*\
  #Big Section Titles / Desktop only
\*------------------------------------*/

.section-title {
    display: none;
    pointer-events: none;

    @include respond-to(lg) {
        z-index: map-get($z-index, section-title);
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        flex-direction: column;
        align-items: center;

        span {
            transform: translateY(-20%);
            transform-origin: center bottom;
            opacity: 0;
            display: inline-block;
        }

        // is previous section in view ?
        .is-in-view + & {

            span {
                &:nth-child(1) {
                    animation: appear-1 0.35s linear 0.4s both;
                }

                &:nth-child(2) {
                    animation: appear-2 0.65s linear both;
                }

                &:nth-child(3) {
                    animation: appear-3 0.6s linear both;
                }
            }
        }
    }
}

// Display a small title on the side
// Triggered by intersection observer
// Interaction Desktop only
.section-small-title {
    @include respond-to(lg) {
        position: fixed;
        top: var(--flash-top);
        left: var(--flash-left, var(--body-p));
        z-index: map-get($z-index, flash-item);
        pointer-events: none;
        opacity: 0;
        transition: opacity .15s var(--trans-func-ease-in-out);

        .is-in-view & {
            opacity: 1;
            pointer-events: all;
            transition: opacity .15s var(--trans-func-ease-in-out) 0.5s;
        }

        // Prevent display of two at a time
        .is-in-view ~ .is-in-view & {
            opacity: 0;
            pointer-events: none;
            transition: opacity .15s var(--trans-func-ease-in-out);
        }
    }
}