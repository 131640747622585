.featured {
    @include respond-to(sm-md-only) {
        margin-top: -30vh;
    }
}

.featured__title {
    @include text-style('headline');

    @include respond-to(sm-md-only) {
        @include hide();
    }
}

.featured__project {
    position: relative;

    &:not(:first-child) {
        margin-top: var(--section-md);
    }

    @include respond-to(lg) {
        min-height: 100vh;
        display: grid;
        align-items: center;

        @include no-touch() {
            // scroll-snap-align: center;
        }
    }
}

.featured__inner {
    position: relative;

    @include respond-to(lg) {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
    }
}

.featured__info {
    @include text-style('headline');
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 2rem;
    padding: 0 var(--body-p);
    position: sticky;
    top: var(--main-header-h);
    z-index: map-get($z-index, project-info);
    padding-bottom: 0.6rem;
    pointer-events: none;

    @include respond-to(lg) {
        position: relative;
        top: auto;
        padding: var(--flash-fade) 0;
        grid-column: 1 / span 1;
        grid-row: 1 / span 1;
        place-self: stretch stretch;
        margin: 2vw 0; // account for downscaled preview videop
    }
}

.featured__info-title {
    @include respond-to(lg) {
        --flash-left: var(--column-10);
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 25.4vw; // align play icon

        // Add play icon here for desktop
        &:after {
            @include play-icon();
        }

        // Move title to the right if is playing
        .has-active-video & {
            text-align: right;
            --flash-left: auto; // magic no.
            right: var(--body-p);
            width: auto;

            &:after {
                display: none;
            }
        }
    }
}

.featured__info-type {
    text-align: right;

    @include respond-to(lg) {
        --flash-left: auto; // magic no.
        right: var(--body-p);
        max-width: 30vw;

        // Hide type to the right if is playing
        .has-active-video & {
            display: none;
        }
    }
}

.featured__player {
    @include respond-to(lg) {
        opacity: var(--video-dimm);

        // Main video playing
        &.is-active {
            opacity: 1;
            z-index: 1; // put on top of reels
        }

        // Add scroll driven animations
        // @see https://developer.chrome.com/articles/scroll-driven-animations/
        @supports (view-timeline-name: --supports) {
            transform-origin: top center;
            view-timeline-name: --revealing-featured;
            view-timeline-axis: block;

            animation: linear reveal-featured backwards;
            animation-timeline: --revealing-featured;
            animation-range: entry 3vw entry calc(100% + 3vw);
        }

        &:after {
            // remove play icon here
            display: none;
        }
    }
}

.featured__reels {
    @include respond-to(lg) {
        grid-column: 1 / span 1;
        grid-row: 1 / span 1;
        place-self: start end;
        width: var(--_reel-w);
        margin-left: auto;
        opacity: var(--video-dimm);
        transition: opacity 0.25s var(--trans-func-ease-out);


        // Main video playing
        .is-active ~ & {
            opacity: 0.1;
        }

        // Activate on hover
        .is-active ~ &,
        & {
            &:hover,
            &:focus,
            &:focus-within {
                opacity: 1;
                z-index: 2;
            }
        }

        // Add scroll driven animations
        // @see https://developer.chrome.com/articles/scroll-driven-animations/
        @supports (view-timeline-name: --supports) {
            transform-origin: top center;
            view-timeline-name: --revealing-reel;
            view-timeline-axis: block;

            animation: linear reveal-reel backwards;
            animation-timeline: --revealing-reel;
            animation-range: entry 100% cover 40%;
        }
    }
}