/*------------------------------------*\
  #Hero
\*------------------------------------*/

.hero {
    min-height: 37vh; // magic number
    position: relative;
    z-index: map-get($z-index, hero);
    pointer-events: none;

    @include respond-to(lg) {
        min-height: 50vh; // let .showreel be shown half on initial viewport

        @include no-touch() {
            // scroll-snap-align: start;
        }
    }
}

.hero__intro {
    // position fix child element to not mess up  scroll-snap-align
    display: flex;
    flex-direction: column;
    align-items: center;
    position: fixed;
    top: var(--main-header-h);
    left: 0;
    right: 0;

    p {
        text-align: center;
        max-width: 130ex;
        padding: 0 var(--body-p);
        position: relative;
        transition: opacity .25s var(--trans-func-ease-in-out);

        .is-scrolled-some & {
            opacity: 0;
        }
    }

    @include respond-to(sm-md-only) {
        p {
            @include text-style('sm');
        }
    }
}