/*------------------------------------*\
  #Footer navigation
\*------------------------------------*/

.nav-footer {
    position: fixed;
	bottom: -0.4ex; // cut logo on bottom
    left: 0;
    right: 0;
    pointer-events: none;

    .is-scrolled-fully & {
        pointer-events: all;
        z-index: map-get($z-index, nav-footer);
    }
}

.nav-footer__navigation {
    opacity: 0;
    transform: translateY(100%);
    @include text-style('headline');
    transition: opacity .25s var(--trans-func-ease-in-out),
                transform .25s var(--trans-func-ease-in-out);
    display: flex;
    justify-content: space-between;
    padding: 0 var(--body-p);
    z-index: map-get($z-index, nav-footer);
    position: relative;

    .is-scrolled-fully & {
        opacity: 1;
        transform: none;
    }

    a {
        @include hover() {
            opacity: 0.6;
        }
    }
}

.nav-footer__credit {
    display: none;

    @include respond-to(lg) {
        display: block;
        @include text-style('headline');
    }
}

.nav-footer__logo {
    visibility: hidden;
    pointer-events: none;
    display: flex;
	align-items: flex-end;
    justify-content: center;
    text-align: center;
    transition: transform .25s var(--trans-func-ease-in-out);
    transform-origin: center bottom;
    line-height: 0.8 !important; // move nav-footer__navigation close to calculated head

    .is-scrolled-some & {
        transform: translateY(30%) scaleY(0.7);
    }

    .is-scrolled-fully & {
        transform: translateY(0);
    }

    @include respond-to(lg) {
        line-height: 0.75 !important; // move nav-footer__navigation close to calculated head
    }
}