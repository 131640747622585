/*------------------------------------*\
  #Main navigation
\*------------------------------------*/

@mixin _dot() {
    content: '';
    display: block;
    height: 0.8rem;
    width: 0.8rem;
    background-color: currentColor;
    border-radius: 50%;
    transform: scale(0.7);
    transition: transform .1s var(--trans-func-ease-in-out),
                opacity .1s var(--trans-func-ease-in-out);
}

.nav-home {
    --nav-left: 0;
    height: var(--main-header-h);
    z-index: map-get($z-index, nav-home);
    display: flex;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    padding: 0 var(--body-p);
    @include text-style('headline');
    overflow: hidden;

    animation: reveal-nav 2s var(--trans-func-ease-out) backwards;
}

.nav-home--simple {
    justify-content: center;
}

.nav-home__list {
    display: flex;
    justify-content: space-between;
    flex: 0 0 100%;

    @include respond-to(lg) {
        transition: transform 2s var(--trans-func-ease-out);
        transform: translateX(calc(var(--nav-left) * -1 * 0.5));
    }
}

.nav-home__list--marquee {
    display: none;

    @include respond-to(lg) {
        display: flex;
        margin-left: calc(8vw + 2 * var(--body-p)); // magic number
    }
}

.nav-home__item {
    display: none;
    align-items: center;
    transition: opacity 0.25s var(--trans-func-ease-out);

    @include hover() {
        opacity: 0.6;
    }

    // only show some links on mobile
    &.is-mobile {
        display: flex;
        gap: 0.5ch;

        &:after {
            @include _dot();
            color: var(--text-active);
            opacity: 0;
            display: inline-block;
        }
    }

    &.is-contact {
        flex-direction: row-reverse;
    }

    @include respond-to(sm-md-only) {
        [data-active-item="showreel"] &,
        [data-active-item="index"] &,
        [data-active-item^="featured-"] & {
            &.is-projects {
                &:after {
                    opacity: 1;
                    transform: none;
                }
            }
        }

        [data-active-item="about"] & {
            &.is-about {
                &:after {
                    opacity: 1;
                    transform: none;
                }
            }
        }

        [data-active-item="contact"] & {
            &.is-contact {
                flex-direction: row-reverse;

                &:after {
                    opacity: 1;
                    transform: none;
                }
            }
        }
    }

    @include respond-to(lg) {
        display: flex;
        gap: 0;

        &.is-active {
            color: var(--text-active);

            @include hover() {
                opacity: 1;
            }
        }
    }
}

.nav-home__link {
    position: relative;

    // extend clickarea
    &:before {
        content: '';
        position: absolute;
        top: -1rem;
        bottom: -1rem;
        left: -1rem;
        right: -1rem;
    }
}

.nav-home__link--dot {
    &:after {
        @include _dot();

        .is-active & {
            transform: none;
        }
    }
}