/*------------------------------------*\
  #MEDIA-BOX
\*------------------------------------*/

/**
 * Provide a media container in order to display media (usually images)
 * cropped to certain ratios and provide a placeholder box while images
 * are still loading
 *
 * 1. Set up a positioning context in which the image can sit.
 * 2. This is the crucial part: where the cropping happens.
 * 3. Show a background style before lazy images are shown
 */

// media box containing images or videos
// by default aspect ratio is defined by content
.media-box {
    position: relative; // [1]
    overflow: hidden; // [2]
    width: 100%;
    height: auto;
    // background: var(--bg-media-box);

    img,
    .video,
    .js-video-loader,
    video {
        width: calc(100% + 2px);
        height: calc(100% + 2px);
        position: absolute;
        top: 0;
        left: 0;
        object-fit: cover;
        object-position: 50% 50%;
    }

    &:before {
        content: '';
        display: block;
        width: 100%;
        padding-bottom: calc(var(--aspect-ratio) * 100%);
        // padding-bottom: 1rem;
        height: 0;
    }

    @include respond-to(lg) {
        border-radius: var(--radius-sm); // border radius for lg by default
    }
}

.media-box--rounded {
    border-radius: var(--radius-sm);
}