/*------------------------------------*\
  #About section
\*------------------------------------*/

.about {
    --_about-gap: 0.5rem;
    margin-top: var(--section-lg);
    padding-top: var(--main-header-h); // scroll below header on anchor click

    p {
        max-width: var(--measure);
    }
}

.about__intro {
    grid-column: 1 / span 12;
    grid-row: 1;
    padding: 0 var(--body-p);
    @include text-style('lg');

    @include respond-to(lg) {
        grid-column: 1 / span 24;
        @include text-style('xl');
        --measure: 60ex;
    }
}

.about__profile {
    margin-top: var(--module-lg);
    grid-column: 1 / span 12;
    grid-row: 2;
    padding: 0 var(--body-p);
    --aspect-ratio: calc(3/2);
    display: grid;
    gap: var(--_about-gap);
    grid-template-columns: 1fr 1fr;

    @include respond-to(lg) {
        margin-top: var(--section-sm);
        padding: 0;
        grid-column: 4 / span 8;
        // min-height: var(--about-gallery-h); // add fixed height to align with .about__gallery

        // define media-box height based on containter
        .media-box {
            --aspect-ratio: 0;
            height: var(--about-gallery-h);
        }
    }
}

.about__profile-text {
    padding-top: 1.8rem; // todo fix magic number?
    word-break: break-word; // fix long word overflow

    p {
        color: var(--text-secondary);
        @include text-style('sm');
    }
}

.about__description {
    margin-top: var(--section-sm);
    grid-column: 1 / span 12;
    grid-row: 3;
    padding: 0 var(--body-p);

    p {
        color: var(--text-secondary);
    }

    @include respond-to(lg) {
        padding: 0;
        grid-column: 7 / span 8;
    }
}

.about__gallery {
    margin-top: var(--module-sm);
    grid-column: 1 / span 12;
    grid-row: 4;
    display: flex;
    gap: var(--_about-gap);
    overflow-x: auto;
    scrollbar-width: none;
    cursor: grab;

    &.grabbing {
        cursor: grabbing;
    }

    &::-webkit-scrollbar {
        display: none;
    }

    @include respond-to(lg) {
        margin-top: var(--section-sm);
        grid-column: 14 / span 11;
        grid-row: 2;
    }
}

.about__gallery-img {
    // No media-box here / sizing by image height
    flex: 0 0 auto;
    position: relative;
    pointer-events: none;

    &:first-child {
        margin-left: var(--_about-gap);
    }

    &:last-child {
        margin-right: var(--_about-gap);
    }

    picture {
        animation: drag-hint 3.5s var(--trans-func-ease-in-out) forwards infinite;
        display: block;

        .is-active & {
            animation: none;
        }
    }

    img {
        border-radius: var(--radius-sm);
        height: var(--about-gallery-h);
        width: auto;

        @supports (view-timeline-name: --supports) {
            transform-origin: left center;
            view-timeline-name: --gallery-img;
            view-timeline-axis: inline;

            animation: show-image linear forwards,
                       hide-image linear forwards;

            animation-timeline: --gallery-img;
            animation-range: entry, exit;
        }
    }

    @include respond-to(lg) {
        &:first-child {
            margin-left: 0;
        }
    }
}

.about__gallery-fig {
    @include text-style('sm');
    position: sticky;
    left: var(--_about-gap);
    margin-bottom: 0.5rem;
    display: inline-block;

    @include respond-to(lg) {
        left: 0;
    }
}

.about__lists {
    margin-top: var(--section-sm);
    grid-column: 1 / span 12;
    padding: 0 var(--body-p);
    grid-row: 5;

    @include respond-to(lg) {
        grid-row: 4;
        padding: 0;
        grid-column: 17 / span 8;
    }
}

.about__list-container {
    display: grid;
    grid-template-columns: 1fr 2fr;
    align-items: start;

    @include respond-to(lg) {
        grid-template-columns: 3fr 1fr;
    }
}

.about__list-headline {
    @include text-style('headline');
    grid-column: 1 / span 1;
    grid-row: 1;
    position: sticky;
    top: var(--main-header-h);
    line-height: 1.7; // align with li

    @include respond-to(lg) {
        grid-column: 2 / span 1;
    }
}

.about__list {
    color: var(--text-secondary);
    grid-column: 2 / span 2;
    grid-row: 1;

    @include respond-to(lg) {
        grid-column: 1 / span 1;
    }
}