/*------------------------------------*\
  #Reels Module
\*------------------------------------*/

.reels {
    // no reels for mobile
    display: none;

    @include respond-to(lg) {
        position: relative;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
    }
}

.reels__navigation {
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
}

.reels__index {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    gap: .4rem;
    padding: .6rem .6rem 0;

    li {
        width: 100%;
        z-index: 10;
        position: relative;

        &:after {
            content: '';
            position: absolute;
            height: .2rem;
            border-radius: .1rem;
            left: 0;
            right: 0;
            background-color: #fff;
            opacity: .4;
        }

        &.is-active {
            &:after {
                opacity: 1;
            }
        }
    }
}

.reels__player {
    grid-row: 1;
    display: none;

    &.is-active {
        display: block;
    }
}