/*------------------------------------*\
  #Contact section
\*------------------------------------*/

.contact {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    min-height: 100vh;
    margin-left: var(--body-p);
    margin-right: var(--body-p);
}

.contact__body {
    padding-bottom: 2vh;

    p {
        color: var(--text-secondary);
        text-align: center;
    }

    a {
        display: inline-block;
    }

    @include respond-to(lg) {
        padding-bottom: 8vw;
    }
}