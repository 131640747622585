/*------------------------------------*\
  #Showreel
\*------------------------------------*/

.showreel {
	display: grid;
    align-items: center;
    min-height: 100vh; // half shown if 16:9 video

    @include respond-to(lg) {
        @include no-touch() {
            // scroll-snap-align: center;
        }
    }
}

.showreel__inner {
    position: relative;
}

.showreel__info {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    pointer-events: none;

    .has-active-video & {
        display: none;
    }
}

.showreel__title {
    @include text-style('headline');
    display: flex;
    padding-left: var(--body-p);
    justify-content: space-between;
    align-items: center;
    transition: opacity .25s var(--trans-func-ease-in-out);
    opacity: 0;
    width: 50%;

    .is-scrolled-some & {
        opacity: 1;
    }

    // Add play icon here for desktop
    &:after {
        @include play-icon();
        margin-right: calc(var(--play-icon-w) * 0.5 * -1); // center
    }

    // Romve if playing
    .is-active & {
        display: none;
    }

    @include respond-to(lg) {
        --flash-left: var(--column-10);
        width: 25.4vw;
        padding-left: 0;

        &:after {
            margin-right: 0;
        }
    }
}

.showreel__player {
    width: 100%;
    border-radius: 0;

    &:after {
        // remove play icon here
        display: none;
    }

    .video {
        animation: reveal 0.3s var(--trans-func-ease-out) backwards;
    }

    @include respond-to(lg) {
        // Add scroll driven animations
        // @see https://developer.chrome.com/articles/scroll-driven-animations/
        @supports (view-timeline-name: --supports) {
            view-timeline-name: --revealing-showreel;
            view-timeline-axis: block;

            animation: linear reveal-showreel both;
            animation-timeline: --revealing-showreel;
            animation-range: entry 0% cover 50%;
        }
    }
}