/*------------------------------------*\
  #Headline with variable font and adjustable width axis based on viewport

  Since there is no CSS only way to convert vw values to unitless values via calc yet
  we need some JS to add a --vw to the body
  @see https://github.com/w3c/csswg-drafts/issues/5635

  BEWARE
  Lots of magic numbers here!


\*------------------------------------*/

.variable-headline {
    white-space: nowrap;
    @include text-style('headline-xl');
    filter: blur(var(--type-blur)); // make it a little unscharf

    // Define viewport range where it should be flexible
    --min-viewport-width: 320;
    --max-viewport-width: 600;
    --viewport-range: calc(var(--max-viewport-width) - var(--min-viewport-width));

    // Define range of variable font axis to be used
    --min-font-width: 75;
    --max-font-width: 90;
    --font-width-range: calc(var(--max-font-width) - var(--min-font-width));

    // Define start and end font size
    --font-size-start: 19.4vw;
    --font-size-end: calc(15.8vw - 5.4px);
    --font-size: calc(var(--font-size-start) + ((var(--font-size-end) - var(--font-size-start)) * (var(--vw) - var(--min-viewport-width)) / var(--viewport-range)));

    // Calculate axis based on view width calculated via JS
    --font-width: min(var(--min-font-width) + (var(--font-width-range) * (var(--vw, 320) - var(--min-viewport-width)) / var(--viewport-range)), var(--max-font-width));

    // Set font variation settings
    font-size: var(--font-size);
    font-variation-settings: "wdth" var(--font-width), "slnt" 0;

    // we need to fine tune for every breakpoint
    // since the variable font axis does not increase linear
    @media screen and (min-width: 600px) {
        --min-viewport-width: 600;
        --max-viewport-width: 1024;

        --min-font-width: 90;
        --max-font-width: 100;

        --font-size-start: calc(15.8vw - 5.4px);
        --font-size-end: calc(14vw - 10px);
    }

    @media screen and (min-width: 1024px) {
        --min-viewport-width: 1024;
        --max-viewport-width: 1600;

        --min-font-width: 100;
        --max-font-width: 130;

        --font-size-start: calc(14vw - 10px);
        --font-size-end: calc(12.6vw - 39px);
    }

    @media screen and (min-width: 1600px) {
        --min-viewport-width: 1600;
        --max-viewport-width: 1920;

        --min-font-width: 130;
        --max-font-width: 150;

        --font-size-start: calc(12.6vw - 39px);
        --font-size-end: 8.875vw;
    }

    @media screen and (min-width: 1920px) {
        --font-size: 8.875vw;
        --font-width: 150;
    }

    // display --font-width value
    /*
    &:after {
        counter-reset: variable var(--font-width);
        content: counter(variable);
        position: absolute;
        top: 0;
        transform: translateY(-100%);
    }*/
}