/*------------------------------------*\
  #ROOT
\*------------------------------------*/

/**
 * Default definition of custom properties
 */

:root {
    // Add token vars
    @include create-custom-properties($text-colors, false, 'text-');
    @include create-custom-properties($bg-colors, false, 'bg-');
    @include create-custom-properties($border-colors, false, 'border-');
    @include create-custom-properties($size-scale, false, 'size-');

    // Add border radius
    @include create-custom-properties($radius, false, 'radius-');

    // Add custom properties for ui definitions defined in setting.ui.scss
    @include create-ui-properties();

    // Define page container
    // - The .container is a wrapper for the inner .page-grid
    // - Since it uses calcs in can unfortunately not be defined inside the ui.settings
    // - Will only be used for lg in this project
    //--container-w: 96vw;

    @include respond-to(lg) {
        // --container-w: min(36rem, 98vw);
    }
}
