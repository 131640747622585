///*----------------------------------*\
//  #ANIMATIONS
//\*----------------------------------*/


@include keyframes(reveal) {
    0% { // always start without transitions to prevent miscalculations for element offsets
        opacity: 0;
    }
    1% {
        transform: translate3d(var(--animate-trans-x, 0), var(--animate-trans-y, 0), 0);
        opacity: var(--animate-fade, 0);
    }
    100% {
        transform: translate3d(0, 0, 0);
        opacity: 1;
    }
}

@include keyframes(show) {
    0%, 100% {
        opacity: 0;
    }
    2%, 98% {
        opacity: 1;
    }
}

@include keyframes(appear-1) {
    0% {
        opacity: 0;
        transform: scaleY(0) translateY(-20%);
    }
    1% {
        transform: scaleY(0.4) translateY(-20%);
        opacity: 1;
    }
    20%, 99% {
        transform: scaleY(1) translateY(-20%);
        opacity: 1;
    }
    100% {
        transform: scaleY(1) translateY(-20%);
        opacity: 0;
    }
}

@include keyframes(appear-2) {
    0% {
        opacity: 1;
        transform: scaleY(0) translateY(-20%);
    }
    15%, 69% {
        transform: scaleY(0.15) translateY(-100%);
        opacity: 1;
    }
    70%, 99% {
        transform: scaleY(1) translateY(-20%);
        opacity: 1;
    }
    100% {
        transform: scaleY(1) translateY(-20%);
        opacity: 0;
    }
}

@include keyframes(appear-3) {
    0% {
        opacity: 1;
        transform: scaleY(0) translateY(-20%);
    }
    20%, 79% {
        transform: scaleY(1) translateY(-20%);
        opacity: 1;
    }
    80%, 100% {
        transform: scaleY(1) translateY(-20%);
        opacity: 0;
    }
}

@include keyframes(logo-1) {
    0% {
        opacity: 1;
        transform: scaleY(0);
    }
    20%, 79% {
        transform: scaleY(1);
        opacity: 1;
    }
    80%, 100% {
        transform: scaleY(1);
        opacity: 0;
    }
}

@include keyframes(logo-2) {
    0% {
        opacity: 1;
        transform: scaleY(0);
    }
    15%, 69% {
        transform: scaleY(0.15) translateY(-20%);
        opacity: 1;
    }
    70%, 99% {
        transform: scaleY(1);
        opacity: 1;
    }
    100% {
        transform: scaleY(1);
        opacity: 0;
    }
}

@include keyframes(logo-3) {
    0% {
        opacity: 0;
        transform: scaleY(0);
    }
    1% {
         transform: scaleY(0.4);
         opacity: 1;
    }
    60%, 100% {
        transform: scaleY(1);
        opacity: 1;
    }
}

@include keyframes(reveal-showreel) {
    0% {
        opacity: 0.5;
        transform: scaleY(0);
    }
    100% {
        opacity: 1;
        transform: none;
    }
}

@include keyframes(reveal-featured) {
    0% {
        opacity: 0.5;
        transform: scaleY(0);
    }
    100% {
        opacity: var(--video-dimm);
        transform: none;
    }
}

@include keyframes(reveal-reel) {
    0% {
        opacity: 0;
        transform: scale(0.8);
    }
    100% {
        opacity: var(--video-dimm);
        transform: none;
    }
}

@include keyframes(reveal-nav) {
    0% {
        transform: translateX(6%);
        opacity: 0;
    }
    100% {
        opacity: 1;
        transform: none;
    }
}

@keyframes show-image {
    0% {
        opacity: 1;
        transform: scaleX(0);
    }
    100% {
        transform: scaleX(1);
        opacity: 1;
    }
}

@keyframes hide-image {
    0% {
        opacity: 1;
        transform: scaleX(1);
        transform-origin: right center;
    }
    100% {
        transform: scaleX(0);
        opacity: 1;
        transform-origin: right center;
    }
}

@keyframes drag-hint {
    0%, 15%, 30%, 100% {
        transform: none;
    }
    5%, 20% {
        transform: translateX(-2rem);
    }
}

@keyframes play-icon {
    0%, 60% {
        transform: translate3d(0,0,0);
        opacity: 1;
    }
    70%, 90% {
        transform: translate3d(50%,0,0);
        opacity: 0;
    }
    90.1% {
        transform: translate3d(-50%,0,0);
        opacity: 0.5;
    }
}

@keyframes play-icon-blink {
    0%, 50% {
        opacity: 1;
    }
    50.1%, 100% {
        opacity: 0;
    }
}