///*----------------------------------*\
//  #UI
//\*----------------------------------*/

// Project-level settings and setup for custom properties dealing with the ui
//
// The $ui-props map will automatically be output as custom properties in the :root styles
// -> ./elements/_elements.root.scss

@mixin create-ui-properties() {
    // default values

    // trans default
    // previously in vars.scss
    --trans-time-xs: 0.125s;
    --trans-time-sm: 0.25s;
    --trans-time-md: 0.5s;
    --trans-time-lg: 0.75s;
    --trans-time-xl: 1s;
    --trans-time-xxl: 3s;
    --trans-time-modal-in: 0.15s;
    --trans-time-modal-out: 0.15s;

    --trans-func-default: cubic-bezier(.1, .6, .4, 1); /// ease out
    --trans-func-ease-in-out: cubic-bezier(.42,0,.55,1);
    // --trans-func-ease-in-out-2: cubic-bezier(1,0,0,1); // extreme in out
    --trans-func-ease-out: cubic-bezier(0, .23, .07, 1);

    // common aspect ratios
    // --ratio-landscape: 0.75; // 3:4
    // --ratio-wide: 0.5; // 1:2
    // --ratio-portrait: 1.333; // 4:3
    // --ratio-cube: 100%;

    // Add default view with for variable font definition
    --vw: 320;

    // picking a save min-wrapper height for logo
    // also defining where logo leaves the scene

    // Body padding/margin
    --body-p: 1.8rem;
    --border-w: 1px;
    --border-radius: 2px;

    // size scale mappings
    --module-sm: var(--size-700);
    --module-md: var(--size-800);
    --module-lg: var(--size-900);
    --section-sm: var(--size-1000);
    --section-md: var(--size-1100);
    --section-lg: var(--size-1200);

    --measure: 76ex;

    --main-header-h: 4.8rem;

    // Default definition for .page-grid
    --grid-rows: 12;
    --column-gap: 0;

    // cluster
    --cluster-gap: var(--body-p);

    --logo-banner-h: 8vw;
    --text-size-headline-xl: 10.8vw;

    --about-gallery-h: 60vw;

    --video-dimm: 0.8;

    --type-blur: 0.4px;

    // flashing headlines
    --flash-fade: 12rem;
    --flash-top: calc(50vh - 1rem);

    // align some items to the tenth grid columjs
    --column-10: calc((100vw - var(--body-p) * 2) / 24 * 10);

    // play icon
    --play-icon-svg: url("data:image/svg+xml;base64, PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIGlkPSJMYXllcl8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA3NS45NyAzOS45OCI+PGRlZnM+PHN0eWxlPi5jbHMtMXtmaWxsOm5vbmU7c3Ryb2tlOiNlNGU0ZTQ7c3Ryb2tlLXdpZHRoOjVweDt9PC9zdHlsZT48L2RlZnM+PHBhdGggY2xhc3M9ImNscy0xIiBkPSJtMi41LDMuMjRsNjMuNjUsMTYuNzVMMi41LDM2Ljc0VjMuMjRaIi8+PC9zdmc+");
    --play-icon-w: 1.56154rem;
    --play-icon-h: .82692rem;

    // Typography
    --text-underline-offset: 0.4ex;
    --text-underline-thickness: 4%;

    // breakpoint md values
    @include respond-to(md) {
        // specific overwrites here

        // Add default view with for variable font definition
        --vw: 600;
    }

    // breakpoint lg values
    @include respond-to(lg) {
        // specific overwrites here
        --border-w: 2px;


        --grid-rows: 24;

        // size scale mappings
        // bump up the section spacing
        --module-sm: var(--size-800);
        --module-md: var(--size-900);
        --module-lg: var(--size-1000);
        --section-sm: var(--size-1100);
        --section-md: var(--size-1200);
        --section-lg: var(--size-1300);

        --about-gallery-h: 20vw;

        --type-blur: 0.05rem;

        // Add default view with for variable font definition
        --vw: 1024;
    }

    // breakpoint xl values
    @include respond-to(xl) {
        // Add default view with for variable font definition
        --vw: 1600;
    }

    // accessible colors
    @media (prefers-contrast: more) {
        --secondary: var(--text-default);
        --tertiary: var(--text-default);
    }
}
