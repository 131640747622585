.index {
    padding-top: var(--section-lg);
    position: relative;
    min-height: 80vh;

    .page-grid {
        align-items: center;
        position: relative;
    }
}

.index__title {
    @include text-style('headline');

    @include respond-to(sm-md-only) {
        padding: 0 var(--body-p);
        position: sticky;
        top: var(--main-header-h);
        z-index: 1;
    }
}

.index__list {
    counter-reset: item;
    grid-column: 1 / span 12;
    margin-top: var(--module-md);

    @include respond-to(lg) {
        grid-column: 7 / span 16;
    }
}

.index__item {
    counter-increment: item;
    position: relative;
    cursor: pointer;
}

.index__description {
    display: grid;
    grid-template-columns: 10fr 2fr;
    grid-gap: 1rem;
    cursor: pointer;
    padding: 0.3rem var(--body-p);
    transition: transform 1s var(--trans-func-ease-out);

    > * {
        @include truncate();
    }

    &:before {
        content: "▷";
        color: var(--text-secondary);
        position: absolute;
        left: var(--body-p);
        top: 0.3rem;
    }

    &:hover,
    &:focus-visible {
        &:before {
            color: var(--text-default);
        }
    }

    .is-active & {
        &:before {
            transform: rotate(90deg);
        }
    }

    @include respond-to(lg) {
        grid-template-columns: 7fr 5fr 2fr;
        padding: 0;

        &:before {
            content: "0" counter(item);
            color: var(--text-secondary);
            position: absolute;
            left: -4ch;
            top: 0;

            li:nth-child(n+10) > & {
                content: counter(item);
            }
        }

        &:after {
            @include play-icon();
            position: absolute;
            left: -8ch;
            top: 0.4rem;
            visibility: hidden;
        }

        @include hover {
            &:after {
                visibility: visible;
            }
        }

        .is-active & {
            &:before {
                transform: none;
            }
        }
    }

    @include respond-to(xl) {
        grid-template-columns: 6fr 6fr 4fr;
    }
}

.index__item-title {
    color: var(--text-secondary);
    padding-left: 2.5ch;

    @include respond-to(lg) {
        padding-left: 0;
    }
}

.index__item-type {
    display: none;
    color: var(--text-tertiary);

    @include respond-to(lg) {
        display: block;
    }
}

.index__item-year {
    color: var(--text-tertiary);
    text-align: end;
}

.index__item-title,
.index__item-type,
.index__item-year {
    .is-active & {
        color: var(--text-default);
    }

    @include hover() {
        color: var(--text-default);
    }
}


.index__video {
    display: none;

    // Activated index item
    .is-active & {
        display: block;
    }

    @include respond-to(lg) {
        padding: 0.6rem 0;

        // Desktop experience
        @include no-touch() {
            position: fixed;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            pointer-events: none;
            z-index: map-get($z-index, index-video);

            // Activated index item
            .is-active & {
                display: grid;
            }

            // Activated video player
            &.has-active-video {
                background: var(--bg-modal);
                cursor: pointer;
                pointer-events: all;
                z-index: map-get($z-index, index-video-active);

                // Display close button
                &:after {
                    content: 'Schließen [Esc]';
                    position: absolute;
                    right: 0;
                    top: 0;
                    padding: 0 var(--body-p);
                    @include text-style('headline');
                    line-height: var(--main-header-h);
                }
            }
        }
    }
}

.index__player {

    // Touch experience / mobile and desktop
    @include no-hover() {
        // Do not show preview videos here
        // Start with vimeo already
        .js-video-loader {
            display: none;
        }
    }

    @include respond-to(lg) {
        // Desktop experience
        @include no-touch() {
            transition: transform .25s var(--trans-func-ease-out);
            transform-origin: center center;
            transform: scale(.35) translateX(60vw);
            opacity: var(--video-dimm);

            &.is-active {
                pointer-events: all;
                transform: none;
                opacity: 1;
            }

            // Remove icon
            &:after {
                display: none;
            }
        }

        // Tablet experience
        @include no-hover() {
            // limit video width to container
            --_max-w: 100%;
        }
    }
}
