/*------------------------------------*\
  #HEADINGS
\*------------------------------------*/

// Resetting margin for p
// We will handle this differently
p {
    margin: 0;
    color: var(--text-default);
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-size: inherit;
    letter-spacing: inherit;
    font-weight: inherit;
}

em,
strong {
    font-weight: inherit;
    font-style: normal;
}

address,
cite {
    font-style: normal;
}
