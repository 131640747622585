/*------------------------------------*\
  #Hero
\*------------------------------------*/

.landing-hero {
    margin-top: var(--main-header-h);
    // min-height: 37vh; // magic number
    padding: 0 var(--body-p);
    @include text-style('lg');

    p {
        max-width: var(--measure);
    }

    @include respond-to(lg) {
        @include text-style('xl');
        // min-height: 50vh; // let .showreel be shown half on initial viewport
    }
}