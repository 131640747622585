/*------------------------------------*\
  #Logo banner
\*------------------------------------*/

.logo {
	position: fixed;
	bottom: -0.5ex; // cut logo on bottom
    left: 0;
    right: 0;
	display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    z-index: map-get($z-index, logo);
    pointer-events: none;
}

.logo__main,
.logo__secondary {
    transition: transform .25s var(--trans-func-ease-in-out);
    transform-origin: center bottom;
    display: flex;
    flex-direction: column;
    justify-content: center;

    span {
        transform-origin: center top;
        opacity: 0;
        display: block;
    }

    .is-scrolled-some & {
        transform: translateY(30%) scaleY(0.7);
    }

    .is-scrolled-fully & {
        transform: translateY(0);
    }
}

.logo__main {
    pointer-events: all;

    @include hover() {
        transition: transform .25s var(--trans-func-ease-in-out);
        transform: translateY(0);
    }
}

.logo__secondary {
    pointer-events: none;

    span {
        opacity: 0;
    }

    .is-scrolled-some & {
        transform: translateY(15%) scaleY(0.85);
    }

    .is-scrolled-fully & {
        transform: translateY(0);
    }
}


.is-loaded {
    // always show main logo if loaded
    .logo__main span {
        opacity: 1;
    }

    // Fade in logo parts if not scrolled yet
    &:not(.is-scrolled-some) {
        .logo__secondary span {
            &:nth-child(1) {
                animation: logo-1 0.6s linear both;
            }

            &:nth-child(2) {
                animation: logo-2 0.65s linear both;
            }
        }

        .logo__main span {
            animation: logo-3 0.35s linear 0.4s both;
        }
    }
}