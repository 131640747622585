/*------------------------------------*\
  #PROSE
\*------------------------------------*/

.prose {
    color: var(--text-secondary);

    a {
        color: inherit;
    }

    a:hover {
        text-decoration: underline;
        text-underline-offset: var(--text-underline-offset);
        text-decoration-color: currentColor;
        text-decoration-thickness: var(--text-underline-thickness);
    }

    h1,
    h2,
    h3,
    h4 {
        & + p,
        & + h4,
        & + ul,
        & + span,
        & + ol {
            --flow-space: 0px;
        }
    }

    h2 {
        --flow-space: var(--section-sm);
    }

    h3 {
        --flow-space: var(--section-sm);
        text-transform: uppercase;
    }

    h2 + h3 {

        --flow-space: var(--size-600);
    }

    ul {
        list-style: disc outside;

        li {
            margin-left: 2ex;

            &:not(:first-child) {
                margin-top: 0.5rem;
            }
        }
    }

    ol {
        li {
            counter-increment: step-counter;
            display: flex;

            &:before {
                content: counter(step-counter);
                flex-shrink: 0;
                width: 3ex;
                display: inline-block;
                font-variant-numeric: tabular-nums;
            }
        }
    }
}