/*------------------------------------*\
  #Basic page
\*------------------------------------*/

.basic {
    margin-top: var(--main-header-h);
    padding: 0 var(--body-p);

    p {
        color: var(--text-secondary);
    }
}

.basic__title {
    pointer-events: none;
    z-index: map-get($z-index, section-title);
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    transform: translateY(-80%);
    white-space: nowrap;
    transition: transform .25s var(--trans-func-ease-in-out),
                opacity .15s var(--trans-func-ease-in-out);

    .is-scrolled-some & {
        transform: translateY(-30%);
        opacity: 0;
    }
}