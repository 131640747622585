
.video-player {
    cursor: zoom-in;
    position: relative;

    // play icon
    &:after {
        @include play-icon();
        position: absolute;
        top: calc(50% - var(--play-icon-h));
        left: calc(50% - var(--play-icon-w) / 2);
        z-index: map-get($z-index, project-video);
    }

    // Autoplaying preview Videos
    // Remove clickability to triggere iframe load on parent click
    .video {
        pointer-events: none;
    }

    // wait while loading
    &.is-active {
        cursor: progress;

        // Hide icon
        &:after {
            display: none;
        }
    }

    &.is-playing {
        cursor: default;

        // Hide preview video if main video is playing
        .video {
            visibility: hidden;
        }
    }
}

.video-player__embed {
    position: absolute;
    top: 0;
    left: 0;//
    width: 100%;
    height: 100%;
    pointer-events: none;

    .video-player.is-active & {
        background: black;
    }

    .is-playing & {
        pointer-events: all;
    }
}