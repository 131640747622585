/*------------------------------------*\
  #FLASH

  Show a flash of an fix positioned item while scrolling by
  Desktop only for this project
\*------------------------------------*/

.flash {
    // Flash wrapper giving a soft overflow with mask-image

    @include respond-to(lg) {
        position: relative;
        height: calc(var(--flash-h, 100%) + (var(--flash-extend) * 2));
        padding-top: var(--flash-fade);
        padding-bottom: var(--flash-fade);
        mask-image: linear-gradient(to bottom,
            rgba(0, 0, 0, 0),
            rgba(0, 0, 0, 1) var(--flash-fade),
            rgba(0, 0, 0, 1) calc(100% - var(--flash-fade)),
            rgba(0, 0, 0, 0) 100%);

        -webkit-mask-image: linear-gradient(to bottom,
            rgba(0, 0, 0, 0),
            rgba(0, 0, 0, 1) var(--flash-fade),
            rgba(0, 0, 0, 1) calc(100% - var(--flash-fade)),
            rgba(0, 0, 0, 0) 100%);
    }
}

.flash__item {
    @include respond-to(lg) {
        position: fixed;
        top: var(--flash-top);
        left: var(--flash-left, var(--body-p));
        pointer-events: none;
        z-index: map-get($z-index, flash-item);
    }
}