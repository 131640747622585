/*------------------------------------*\
  #Img Fade
\*------------------------------------*/

/**
 * Fade in native lazy loading images after load
 */



.img-fade {
    // fading in picture to keep img transitions for hover etc
    picture {
        transition: opacity 1s var(--trans-func-ease-out);
    }
}

.img-fade-waiting {
    // hide picture while waiting for load
    picture {
        transition: none;
        opacity: 0;
    }
}